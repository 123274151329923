<template>
  <!--设置标签-->
  <el-dialog
    title="切换虚拟用户"
    width="50%"
    :visible.sync="isShow"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    @close="closeModel"
  >
    <div class="page-content-search">
      <el-form
        ref="form"
        :model="search"
        size="mini"
        :label-width="this.env.search_label_width"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="用户昵称">
              <el-input v-model="search.user_nick"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="手机号">
              <el-input v-model="search.phone"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item>
              <el-button
                icon="el-icon-search"
                size="mini"
                type="primary"
                @click="is_search"
                >搜索
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-table
      size="medium"
      border
      v-loading="loading"
      :data="tableData"
      style="width: 100%"
    >
      <el-table-column prop="user_nick" label="昵称" width="140" />
      <el-table-column prop="user_uuid" label="用户id" />
      <el-table-column prop="avatar_url" label="头像" width="80">
        <template slot-scope="scope">
          <el-avatar shape="square" :size="22" :src="scope.row.avatar_url"></el-avatar>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="手机号" width="120" />
      <el-table-column prop="gender" label="性别" width="160">
        <template slot-scope="scope">
          <el-tag size="mini" v-if="scope.row.gender === 0" type="info">未知</el-tag>
          <el-tag size="mini" v-if="scope.row.gender === 1">男</el-tag>
          <el-tag size="mini" v-if="scope.row.gender === 2" type="danger">女</el-tag>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button @click="chooseUser(scope.row)" type="primary" size="mini"
            >选择</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 20px"></div>
    <el-pagination
      @current-change="getlist"
      :page-size="this.env.pageSize"
      :pager-count="7"
      background
      layout="prev, pager, next, total"
      :current-page.sync="page"
      :total="count"
    >
    </el-pagination>
  </el-dialog>
</template>

<script>
export default {
  props: {
    isShow: {
      props: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false, // 加载状态
      count: 0, // 数据总条数
      page: 1, // 当前页数
      tableData: [], // 添加页面数据
      search: {}, // 搜索内容
      listData: [],
    };
  },
  created() {
    this.getlist();
  },
  methods: {
    getlist() {
      let postdata = {
        api_name: "note.fictitious.getuserlist",
        pagesize: this.env.pageSize,
        page: this.page,
        token: this.Tool.get_l_cache("token"),
      };
      this.loading = true;
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.search);
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.tableData = json.data.list;
          this.count = json.data.count;
        } else {
          this.Tool.errormes(json);
        }
      });
    },
    is_search() {
      this.page = 1;
      this.getlist();
    },
    chooseUser(item) {
      const { user_uuid } = item;
      const virtualUserItem = this.Tool.get_s_cache("virtualUserItem");
      if (!virtualUserItem) {
        this.Tool.set_s_cache("virtualUserItem", item);
      } else {
        const id = virtualUserItem.user_uuid;
        if (id !== user_uuid) {
          this.Tool.set_s_cache("virtualUserItem", item);
        }
      }
      this.$emit("chooseUser", item);
    },
    closeModel() {
      this.$emit("onClose");
    },
  },
};
</script>
