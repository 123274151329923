<template>
  <div>
    <!--面包屑-->
    <div class="page-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/note/note' }">笔记列表</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/note/operate' }">操作笔记</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div v-loading="loading" class="page-content-x">
      <div style="width: 60%">
        <el-row>
          <el-col :span="16"><h3>笔记操作</h3></el-col>
        </el-row>
        <div class="operate_info" style="display: flex; align-items: center">
          <p style="margin-right: 12px">
            虚拟用户：{{
              virtualUserData ? virtualUserData.user_nick : "请选择需要操作的虚拟用户"
            }}
          </p>
          <el-button
            v-if="is_auth('note.fictitious.getuserlist')"
            size="medium"
            type="primary"
            @click="changeUser"
            >切换用户</el-button
          >
        </div>
        <p style="height: 1px; background: #ccc" />
        <!-- 笔记内容部分 -->
        <section v-if="noteDetail" class="operate_content" style="box-sizing: border-box">
          <!-- 标题 -->
          <div>
            <h3>{{ noteDetail.title }}</h3>
          </div>
          <!-- 内容 -->
          <el-card class="box-card">
            <!-- 视频 -->
            <video v-if="noteDetail.video" width="60%" controls>
              <source :src="noteDetail.video" type="video/mp4" />
              您的浏览器不支持Video标签。
            </video>
            <!-- 图片 -->
            <div
              style="
                width: 120px;
                height: 120px;
                margin-right: 24px;
                display: inline-block;
              "
              v-for="(item, index) in noteDetail.photo"
              :key="index"
            >
              <el-image
                style="width: 100%; height: 100%"
                :src="item"
                fit="contain"
                :preview-src-list="noteDetail.photo"
              >
              </el-image>
            </div>
            <p>
              {{ noteDetail.content }}
            </p>
          </el-card>
          <!-- 点赞和回复部分 -->
          <div style="display: flex; margin-top: 24px">
            <div
              v-if="is_auth('note.fictitious.thumbs')"
              class="operate_control_btn"
              :class="{ operate_control_actyBtn: noteDetail.is_thumbs === 1 }"
              @click="thumbsNote"
            >
              <img
                :src="
                  noteDetail.is_thumbs === 0
                    ? 'https://img.gymmartsports.com/gymmartsports/materialimg/46a78202209300948077490.png?imageslim'
                    : 'https://img.gymmartsports.com/gymmartsports/materialimg/443b42022093009481033.png?imageslim'
                "
                alt=""
              />
              <p>· {{ noteDetail.thumbs_sum }}</p>
            </div>
            <div
              v-if="is_auth('note.fictitious.reply')"
              class="operate_control_btn"
              @click="reply(noteDetail, 'note')"
            >
              <img
                src="https://img.gymmartsports.com/gymmartsports/materialimg/6f605202209301000393106.png?imageslim"
                alt=""
              />
              <p>· {{ noteDetail.reply_sum }}</p>
            </div>
          </div>
          <!-- 作者信息部分 -->
          <div style="display: flex; align-items: center; margin-top: 24px">
            <!-- 头像 -->
            <div
              style="
                width: 84px;
                height: 84px;
                border-radius: 100%;
                background: #999;
                overflow: hidden;
              "
            >
              <el-image
                :src="noteDetail.avatar_url"
                fit="contain"
                style="width: 100%; height: 100%"
              />
            </div>
            <!-- 昵称 -->
            <div style="margin-left: 12px; font-size: 14px">
              <p>{{ noteDetail.user_nick }}</p>
            </div>
            <!-- 关注按钮 -->
            <div
              v-if="is_auth('note.fictitious.subscribe')"
              class="operate_btn"
              :class="{ operate_banBtn: noteDetail.is_subscribe === 1 }"
              @click="subscribeUser"
            >
              <span>{{ noteDetail.is_subscribe === 0 ? "关注TA" : "已关注" }}</span>
            </div>
          </div>
        </section>
        <!-- 评论部分 -->
        <section style="margin-top: 24px">
          <p>评论列表</p>
          <!-- 评价列表 -->
          <div class="operate_comment">
            <!-- 评论模块 -->
            <div
              v-for="(item, index) in commentList"
              :key="index"
              class="comment_item"
              style="
                border-bottom: 1px solid #999;
                padding-bottom: 24px;
                margin-bottom: 24px;
              "
            >
              <!-- 评论用户信息部分 -->
              <div class="comment_item_info">
                <!-- 用户头像部分 -->
                <div class="comment_item_avatar">
                  <el-image
                    :src="item.avatar_url"
                    fit="contain"
                    style="width: 100%; height: 100%"
                  />
                </div>
                <!-- 用户id与评论时间 -->
                <div class="comment_item_info_nick">
                  <div>
                    <p>{{ item.user_nick }}</p>
                    <p>{{ item.create_time }}</p>
                  </div>
                  <div style="display: flex">
                    <div
                      v-if="is_auth('note.fictitious.replythumbs')"
                      class="operate_control_btn"
                      :class="{ operate_control_actyBtn: item.is_thumbs === 1 }"
                      @click="replyThumbs(item.reply_uuid)"
                    >
                      <img
                        :src="
                          item.is_thumbs === 0
                            ? 'https://img.gymmartsports.com/gymmartsports/materialimg/46a78202209300948077490.png?imageslim'
                            : 'https://img.gymmartsports.com/gymmartsports/materialimg/443b42022093009481033.png?imageslim'
                        "
                        alt=""
                      />
                      <p>· {{ item.thumbs_sum }}</p>
                    </div>
                    <div
                      v-if="is_auth('note.fictitious.reply')"
                      class="operate_control_btn"
                      @click="reply(item, 'comment')"
                    >
                      <img
                        src="https://img.gymmartsports.com/gymmartsports/materialimg/6f605202209301000393106.png?imageslim"
                        alt=""
                      />
                      <p>· {{ item.reply_sum }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 评论内容部分 -->
              <div class="comment_item_content">
                <span>{{ item.content }}</span>
              </div>
              <!-- 楼中楼回复部分 -->
              <div v-if="item.reply_sum > 0" class="comment_item_reply">
                <div v-if="item.isOpen">
                  <div
                    class="comment_item"
                    v-for="(lItem, lIndex) in item.replyList.slice(1)"
                    style="margin-bottom: 12px"
                    :key="lIndex"
                  >
                    <!-- 评论用户信息部分 -->
                    <div class="comment_item_info">
                      <!-- 用户头像部分 -->
                      <div class="comment_item_avatar">
                        <el-image
                          :src="lItem.avatar_url"
                          fit="contain"
                          style="width: 100%; height: 100%"
                        />
                      </div>
                      <!-- 用户id与评论时间 -->
                      <div class="comment_item_info_nick">
                        <div>
                          <p>{{ lItem.user_nick }}</p>
                          <p>{{ lItem.create_time }}</p>
                        </div>
                        <div style="display: flex">
                          <div
                            v-if="is_auth('note.fictitious.replythumbs')"
                            class="operate_control_btn"
                            :class="{ operate_control_actyBtn: lItem.is_thumbs === 1 }"
                            @click="replyThumbs(lItem.reply_uuid)"
                          >
                            <img
                              :src="
                                lItem.is_thumbs === 0
                                  ? 'https://img.gymmartsports.com/gymmartsports/materialimg/46a78202209300948077490.png?imageslim'
                                  : 'https://img.gymmartsports.com/gymmartsports/materialimg/443b42022093009481033.png?imageslim'
                              "
                              alt=""
                            />
                            <p>· {{ lItem.thumbs_sum }}</p>
                          </div>
                          <div
                            v-if="is_auth('note.fictitious.reply')"
                            class="operate_control_btn"
                            @click="reply(lItem, 'commentReply')"
                          >
                            <img
                              src="https://img.gymmartsports.com/gymmartsports/materialimg/6f605202209301000393106.png?imageslim"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- 评论内容部分 -->
                    <div class="comment_item_content">
                      <span>回复</span>
                      <span style="color: #466989">{{ lItem.to_user_nick }}：</span>
                      <span>{{ lItem.content }}</span>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div
                    v-if="is_auth('note.fictitious.replylist')"
                    class="comment_showBtn"
                    @click="getCommentReply(item, index)"
                  >
                    <span>共{{ item.reply_sum }}条回复，点击展开</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- 回复弹窗 -->
        <el-dialog
          :title="`回复：${replyData ? replyData.user_nick : ''}`"
          width="50%"
          :visible.sync="isReplyShow"
          :close-on-press-escape="false"
          :close-on-click-modal="false"
          @close="closeReply"
        >
          <el-input type="textarea" v-model="commentValue" />
          <div style="margin-top: 24px">
            <el-button type="primary" @click="submitReply">发送</el-button>
            <el-button @click="closeReply">取消</el-button>
          </div>
        </el-dialog>
      </div>
    </div>
    <virtual-model
      :is-show="isModelShow"
      @onClose="closeModel"
      @chooseUser="chooseUser"
    />
  </div>
</template>

<script>
import VirtualModel from "./components/VirtualModel.vue";
export default {
  components: { VirtualModel },
  data() {
    return {
      loading: true,
      isModelShow: false,
      isReplyShow: false,
      note_uuid: "",
      commentValue: "",
      replyType: "", // 回复评论类型
      virtualUserData: null,
      noteDetail: null,
      replyData: null,
      commentList: [],
    };
  },
  created() {
    // 获取本地缓存的虚拟用户信息
    const userItem = this.Tool.get_s_cache("virtualUserItem");
    if (userItem) {
      this.virtualUserData = userItem;
      this.getNoteInfo();
      this.getCommentList();
    }
  },
  methods: {
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    // 获取笔记详情页
    getNoteInfo() {
      const { virtualUserData } = this;
      let { note_uuid } = this.$route.query;
      this.note_uuid = note_uuid;
      let postdata = {
        api_name: "note.note.details",
        token: this.Tool.get_l_cache("token"),
        note_uuid,
      };
      postdata["user_uuid"] = virtualUserData ? virtualUserData.user_uuid : "";
      this.loading = true;
      this.Tool.post_data("oss", postdata, (res) => {
        if (res.code === 0) {
          this.noteDetail = res.data;
        } else if (res.code === 10005) {
          this.Tool.errormes(res);
        }
        this.loading = false;
      });
    },
    // 获取回复评论列表
    getCommentList() {
      const { virtualUserData } = this;
      let { note_uuid } = this.$route.query;
      this.note_uuid = note_uuid;
      let postdata = {
        api_name: "note.fictitious.replylist",
        token: this.Tool.get_l_cache("token"),
        note_uuid,
      };
      postdata["user_uuid"] = virtualUserData ? virtualUserData.user_uuid : "";
      this.loading = true;
      this.Tool.post_data("oss", postdata, (res) => {
        if (res.code === 0) {
          const data = res.data.list;
          for (let i in data) {
            data[i]["isOpen"] = false;
            data[i]["replyList"] = [];
            data[i]["page"] = 1;
          }
          this.commentList = res.data.list;
        } else if (res.code === 10005) {
          this.Tool.errormes(res);
        }

        this.loading = false;
      });
    },
    // 获取评论楼中楼
    getCommentReply(item, index) {
      const { virtualUserData, commentList } = this;
      const { reply_key } = item;
      let { note_uuid } = this.$route.query;
      this.note_uuid = note_uuid;
      let postdata = {
        api_name: "note.fictitious.replylist",
        token: this.Tool.get_l_cache("token"),
        note_uuid,
        reply_key,
      };
      postdata["user_uuid"] = virtualUserData ? virtualUserData.user_uuid : "";
      this.loading = true;
      this.Tool.post_data("oss", postdata, (res) => {
        this.loading = false;
        if (res.code === 0) {
          commentList[index]["isOpen"] = true;
          commentList[index]["replyList"] = res.data.list;
          this.commentList = commentList;
        }
      });
    },
    changeUser() {
      this.isModelShow = true;
    },
    closeModel() {
      this.isModelShow = false;
    },
    // 回复评论
    reply(item, type) {
      this.replyData = item;
      this.isReplyShow = true;
      this.replyType = type;
    },
    // 关闭评论弹窗
    closeReply() {
      this.replyData = null;
      this.isReplyShow = false;
      this.commentValue = "";
    },
    // 发送评论
    submitReply() {
      const { virtualUserData, commentValue, replyData, note_uuid, replyType } = this;
      const { reply_key, user_uuid, reply_uuid } = replyData;
      const postdata = {
        api_name: "note.fictitious.reply",
        token: this.Tool.get_l_cache("token"),
        content: commentValue,
        note_uuid,
      };
      if (replyType === "comment" || replyType === "commentReply") {
        postdata["reply_key"] = reply_key;
        postdata["to_reply_uuid"] = reply_uuid;
        postdata["to_user_uuid"] = user_uuid;
      }
      postdata["user_uuid"] = virtualUserData ? virtualUserData.user_uuid : "";
      this.loading = true;
      this.Tool.post_data("oss", postdata, (res) => {
        this.loading = false;
        if (res.code === 0) {
          this.isReplyShow = false;
          this.getCommentList();
          this.getNoteInfo();
          this.$message({
            message: "评论成功",
            type: "success",
            duration: this.env.message_duration,
          });
        }
      });
    },
    // 选择虚拟用户
    chooseUser(item) {
      this.virtualUserData = item;
      this.isModelShow = false;
      this.$message({
        message: "操作成功",
        type: "success",
        duration: this.env.message_duration,
        onClose: () => {
          this.getNoteInfo();
          this.getCommentList();
        },
      });
    },
    // 点赞笔记
    thumbsNote() {
      const { virtualUserData, note_uuid } = this;
      const { user_uuid } = virtualUserData;
      let postdata = {
        api_name: "note.fictitious.thumbs",
        token: this.Tool.get_l_cache("token"),
        note_uuid,
        user_uuid,
      };
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        if (json.code === 0) {
          this.$message({
            message: "操作成功",
            type: "success",
            duration: this.env.message_duration,
            onClose: () => {
              this.getNoteInfo();
            },
          });
        }
      });
    },
    // 关注用户
    subscribeUser() {
      const { virtualUserData, noteDetail } = this;
      const { is_subscribe } = noteDetail;
      const quilt_user_uuid = noteDetail.user_uuid;
      const { user_uuid } = virtualUserData;
      let postdata = {
        api_name:
          is_subscribe === 0
            ? "note.fictitious.subscribe"
            : "note.fictitious.unsubscribe",
        token: this.Tool.get_l_cache("token"),
        quilt_user_uuid,
        user_uuid,
      };
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        if (json.code === 0) {
          this.$message({
            message: is_subscribe === 0 ? "关注成功" : "取消关注成功",
            type: "success",
            duration: this.env.message_duration,
            onClose: () => {
              this.getNoteInfo();
            },
          });
        }
      });
    },
    // 回复点赞
    replyThumbs(reply_uuid) {
      const { virtualUserData } = this;
      const { user_uuid } = virtualUserData;
      let postdata = {
        api_name: "note.fictitious.replythumbs",
        token: this.Tool.get_l_cache("token"),
        reply_uuid,
        user_uuid,
      };
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        if (json.code === 0) {
          this.$message({
            message: "操作成功",
            type: "success",
            duration: this.env.message_duration,
            onClose: () => {
              this.getCommentList();
            },
          });
        }
      });
    },
  },
};
</script>

<style>
.operate_btn {
  width: 78px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  font-size: 14px;
  background: #fc142f;
  color: #fff;
  border-radius: 50px;
  margin-left: 12px;
  cursor: pointer;
}
.operate_banBtn {
  background: #ccc;
}
.operate_btn:hover {
  opacity: 0.8;
}
.operate_control_btn {
  height: 24px;
  text-align: center;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.operate_control_btn img {
  width: 24px;
  height: 100%;
}
.operate_control_btn p {
  margin: 0;
  padding: 0;
  margin-left: 6px;
}
.operate_control_actyBtn {
  color: #fc142f;
}
.operate_control_btn:hover {
  opacity: 0.8;
}
.operate_control_btn:nth-child(2) {
  margin-left: 24px;
  color: #fc142f;
}
.comment_item {
  width: 100%;
}
.comment_item_info {
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.comment_item_avatar {
  width: 42px;
  height: 42px;
  border-radius: 100%;
  background: #999;
  overflow: hidden;
}
.comment_item_info_nick {
  flex: 1;
  color: #999;
  font-size: 12px;
  margin-left: 12px;
  display: flex;
  justify-content: space-between;
}
.comment_item_info_nick p {
  padding: 0;
  margin: 0;
  margin-left: 6px;
}
.comment_item_content {
  font-size: 14px;
  box-sizing: border-box;
  padding-left: 54px;
}
.comment_item_reply {
  width: 100%;
  box-sizing: border-box;
  padding-left: 24px;
  margin-top: 12px;
}
.comment_showBtn {
  display: inline-block;
  background: #f5f5f5;
  font-size: 12px;
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 10px;
}
</style>
